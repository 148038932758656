export default [
  {
    header: 'Center',
    action: 'default',
  },
  {
    title: 'User Account',
    icon: 'UserIcon',
    route: 'user-account',
    action: 'default',
  },
]
