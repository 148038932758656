export default [
  {
    header: 'Center',
    icon: 'ServerIcon',
    action: 'default',
    children: [
      {
        title: 'User Account',
        icon: 'UserIcon',
        route: 'user-account',
        action: 'default',
      },
    ],
  },
]
